import { isExpired, decodeToken } from "react-jwt";

export function GetUser() {
    //const { isExpired } = useJwt(localStorage.getItem("id_token"));

    if(isExpired(localStorage.getItem("id_token"))) {
        return '';
    }
    //console.log('decodedToken:' + decodeToken(localStorage.getItem("id_token")).user);
    return decodeToken(localStorage.getItem("id_token")).user;
};

export default function GetToken() {
    //const { isExpired } = useJwt(localStorage.getItem("id_token"));

    //if(isExpired) {
    if(isExpired(localStorage.getItem("id_token"))) {
        return '';
    }
    //console.log('id_token:' + localStorage.getItem('id_token'));
    return localStorage.getItem('id_token');
};
