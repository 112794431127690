import { useState, useEffect, useCallback , useContext} from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import DraftsIcon from "@mui/icons-material/Drafts";
import { blue, red, green, pink, orange} from "@mui/material/colors";

import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  IconButton,
  Paper,
  Popper,
  Stack,
  Tab,
  Tabs,
  Typography,
  ListItemSecondaryAction,
} from "@mui/material";


import MainCard from "../components/MainCard";
import ReactApexChart from 'react-apexcharts';
import { default as GetToken, GetUser } from "../components/Token";
import {Context} from '../App';
import RouterOutlined from "@mui/icons-material/RouterOutlined";
import VideocamOutlined from "@mui/icons-material/VideocamOutlined";
import SensorsOutlined from "@mui/icons-material/SensorsOutlined";


//{} is very import, it means default export when without {}
//import { doConnect } from "../slices/connect";

const DashboardDefault = () => {
  const dispatch = useDispatch();
  //const { connectMessage } = useSelector((state) => state.connect);

  const barHeight = 200;

  // chart options
  const barChartOptions = {
    chart: {
      type: "bar",
      height: barHeight,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "45%",
        borderRadius: 4,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
  };

  const [connectMessage, setConnectMessage] = useState("{}");

  const theme = useTheme();

  const { primary, secondary , warning} = theme.palette.text;
  const info = theme.palette.info.light;

  const [seriesData, updateSeriesData] = useState([]);
  const series = [
    {
      name: "events",
      data: seriesData,
    },
  ];

  //let series = {data: [1,1,1,1,1,1]};
  const [options, setOptions] = useState(barChartOptions);
  /*
  const Connect = () => {
    dispatch(doConnect())
      .unwrap()
      .then(() => {})
      .catch(() => {
        console.log("doLogin dispatch error.");
      });
  };
*/
  const token = GetToken();
  const user = GetUser();

  const {navMenu, freshNavMenu, dataChannel} = useContext(Context);
/*
  if(dataChannel) {
    dataChannel.setConnectedCallback(() => {
      console.log("++++++++++++++++++++++ ConnectedCallback");
      const target = {target: "rtsp://admin:123456@192.168.160.172:554/mpeg4cif"};
      const apiList ={
        api: "listfiles",
        payload: JSON.stringify(target),
      };
      dataChannel.apiRequest(apiList).then((res)=>{console.log("+++++++++++:" + res);}).catch((error) => {
        console.log("listfiles error: " + error);
      });
    });
  }
  */
  useEffect(() => {
    //console.log("useEffect called.");
    const config = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: user,
        token: token,
      }),
      credentials: "include",
    };

    const fetchConnect = async () => {
      const res = await fetch(
        "/api/getdevices",
        config
      ).then((res) => res.json());
      //const res = await fetch('/api/getdevices?token=' + token + '&user=' + user, config);
      //const connect = await res.json();

      const PageIcons = {
        RouterOutlined,
        VideocamOutlined,
        SensorsOutlined
      };
    
      // nav is the ref of navMenu
      let nav = [];
      nav = navMenu;
      let flag = false;
      if (res.gateway && res.gateway.length > 0) {
        if (nav.items[1]) {
          nav.items[1].children.length = 0;
          for (let i = 0; i < res.gateway.length; i++) {
            let a = {
              id: "",
              title: "",
              type: "item",
              url: "/gateway",
              icon: PageIcons.RouterOutlined,
              target: false,
            };
            a.id = res.gateway[i];
            a.title = res.gateway[i];
            nav.items[1].children.push(a);
          }
        }
        flag = true;
      }
      if (res.camera && res.camera.length > 0) {
        if (nav.items[2]) {
          nav.items[2].children.length = 0;
          for (let i = 0; i < res.camera.length; i++) {
            let a = {
              id: "",
              title: "",
              type: "item",
              url: "/camera",
              icon: PageIcons.VideocamOutlined,
              target: false,
            };
            a.id = res.camera[i];
            a.title = res.camera[i];
            //console.log("### camera:" + a.toString());
            nav.items[2].children.push(a);
          }
        }
        flag = true;
      }
      if (res.iot && res.iot.length > 0) {
        if (nav.items[3]) {
          nav.items[3].children.length = 0;
          for (let i = 0; i < res.iot.length; i++) {
            let a = {
              id: "",
              title: "",
              type: "item",
              url: "/plug",
              icon: PageIcons.SensorsOutlined,
              target: false,
            };
            a.id = res.iot[i];
            a.title = res.iot[i];
            nav.items[3].children.push(a);
          }
        }
        flag = true;
      }
      if(flag) {
        freshNavMenu(nav);
      }

      // ReactApexChart must update data of series for updating bars
      // otherwise no updating
      if(res.events && res.events.length > 0) {
        updateSeriesData(res.events.reverse());
      }
      //console.log("res:" + JSON.stringify(res));
      setConnectMessage(JSON.stringify(res));
    };
    //try {
    //  fetchConnect();
    fetchConnect().catch((error) => {
      setConnectMessage("{}");
      console.log("=== fetchConnect error: " + error);
    });
    //} catch (error) {
    // setConnectMessage('');
    //}
    //dispatch();
  }, []);
  // Using [] to remove rendering triggered by dispatch
  //console.log("+++ connectMessage:" + connectMessage);

  //{"camera":["cam1","cam2"],"camera_number":2,"events":[0,1,0,2,0,1,5,0,0,0,0,2,0,0,0,0,0,0,0,1,0,1,1,0],"gateway":["gateway1"],"gateway_number":1,"iot":["iot1","iot2"],"iot_number":2,"online":["gateway1"],"states":["gateway1: 2023-04-08 10:50:20 online","gateway1: 2023-04-07 23:38:03 offline","gateway1: 2023-04-07 21:21:38 online","gateway1: 2023-04-07 21:18:36 offline"],"total_number":5} Dashboard.js:139
  //{"camera":["cam1","cam2"],"camera_number":2,"events":[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],"gateway":["gateway1"],"gateway_number":1,"iot":["iot1","iot2"],"iot_number":2,"offline":["gateway1"],"total_number":5}
  let connect = JSON.parse(connectMessage);
  let offlineNumber = "0";
  let onlineNumber = "0";
  let totalNumber = "0";
  let eventNumber = "0";
  if (connect.total_number) {
    totalNumber = String(connect.total_number);
  }
  if (connect.offline) {
    offlineNumber = String(connect.offline.length);
  }
  if (connect.online) {
    onlineNumber = String(connect.online.length);
  }

  let count = 0;
  if(connect.events && connect.events.length > 0){
    for(let i = 0; i < connect.events.length; i++)
    {
      count += connect.events[i];
      //barChartOptions.xaxis.categories[i] = i;
      //series[0].data[i] = connect.events[connect.events.length - i -1];
    }
  }

  eventNumber = String(count);

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      {/* row 1 */}
      <Grid item xs={12}>
        <Typography variant="h5">Devices</Typography>
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
      <MainCard contentSX={{ p: 2.25 }} style={{ backgroundColor: pink[200], color: "black"}}>
        <Box sx={{ p: 2, pb: 0}}>
          <Stack spacing={0.5}>
            <Typography variant="h5" color="white">
              All:
            </Typography>
            <Grid container alignItems="center">
              <Grid item>
                <Typography variant="h4" color="inherit">
                  {totalNumber}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </MainCard>
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
      <MainCard contentSX={{ p: 2.25 }} style={{ backgroundColor: green[200], color: "black"}}>
        <Box sx={{ p: 2, pb: 0 }}>
          <Stack spacing={0.5}>
            <Typography variant="h5" color="white">
              Online:
            </Typography>
            <Grid container alignItems="center">
              <Grid item>
                <Typography variant="h4" color="inherit">
                  {onlineNumber}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </MainCard>
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
      <MainCard contentSX={{ p: 2.25 }} style={{ backgroundColor: orange[100], color: "red"}}>
        <Box sx={{ p: 2, pb: 0 }}>
          <Stack spacing={0.5}>
            <Typography variant="h5" color="white">
              Offline:
            </Typography>
            <Grid container alignItems="center">
              <Grid item>
                <Typography variant="h4" color="inherit">
                  {totalNumber - onlineNumber}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </MainCard>
      </Grid>
      {/* row 2 */}
      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">Events</Typography>
          </Grid>
          <Grid item />
        </Grid>
        <MainCard sx={{ mt: 2, height: 230 }} content={false}>
          <Box sx={{ p: 1, pb: 0 }}>
            <Stack spacing={2}>
              <Typography variant="h6" color="textSecondary">
                Events in the last day: {eventNumber}
              </Typography>
            </Stack>
          </Box>
          <div id="chart">
            <ReactApexChart options={options} series={series} type="bar" height={barHeight} />
        </div>
        </MainCard>
      </Grid>
      {/* row 3 */}
      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">Device Status</Typography>
          </Grid>
          <Grid item />
        </Grid>
        <MainCard sx={{ mt: 2 }} content={false}>
          <Box sx={{ p: 1, pb: 0 }}>
            <Stack spacing={2}>
              <Typography variant="h6" color="textSecondary">
                Connectivities
              </Typography>
            </Stack>
          </Box>
          {connect.states &&
            connect.states.map((item, index) => (
              <ListItemButton key={index}>
                <ListItemText
                  key={index}
                  primary={<Typography variant="subtitle1">{item}</Typography>}
                />
              </ListItemButton>
            ))}
        </MainCard>
      </Grid>
    </Grid>
  );
};
/*
          <ListItemButton>
            <ListItemText
              primary={<Typography variant="subtitle1">Gateway2</Typography>}
            />
            <ListItemSecondaryAction>
              <Stack alignItems="flex-end">
                <Typography variant="subtitle1" noWrap>
                  online
                </Typography>
              </Stack>
            </ListItemSecondaryAction>
          </ListItemButton>
*/
export default DashboardDefault;
