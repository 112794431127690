import { React, useState, useEffect, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { grey } from "@mui/material/colors";
import { login } from "../slices/auth";
import { useTheme, styled, alpha } from "@mui/material/styles";
import MainCard from "../components/MainCard";
import Logo from "../components/logo.png";
import SvgShape from "../components/shape-avatar.svg";
import Cover from "../components/cover.png";
import { blue, green, orange, yellow, deepPurple, pink, brown, teal} from "@mui/material/colors";

const SvgColor = forwardRef(({ src, sx, ...other }, ref) => (
  <Box
    component="span"
    className="svg-color"
    ref={ref}
    sx={{
      width: 24,
      height: 24,
      display: "inline-block",
      bgcolor: "currentColor",
      mask: `url(${src}) no-repeat center / contain`,
      WebkitMask: `url(${src}) no-repeat center / contain`,
      ...sx,
    }}
    {...other}
  />
));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: "absolute",
  left: theme.spacing(3),
  bottom: theme.spacing(-2),
}));

const StyledCover = styled("img")({
  top: 0,
  width: "100%",
  height: "30%",
  objectFit: "cover",
  position: "absolute",
});

const StyledCardMedia = styled("div")({
  position: "relative",
  paddingTop: "calc(100% * 3 / 4)",
});

const Login = () => {
  const dispatch = useDispatch();

  const { isLoading, isAuthorized, message } = useSelector(
    (state) => state.auth
  );

  const INITIAL = { text: "", error: "" };

  //const [windowSize, setWindowSize] = useState(getWindowSize());
  const [user, setUser] = useState(INITIAL);
  const [password, setPassword] = useState(INITIAL);
  /*
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [dispatch]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
*/
  const doLogin = (username, password) => {
    dispatch(login({ username, password }))
      .unwrap()
      .then(() => {})
      .catch(() => {
        //console.log("doLogin dispatch error.");
      });
  };

  const handleDoLogin = () => {
    doLogin(user.text, password.text);
  };

  const handleDoLoginWithPassword = (password) => {
    doLogin(user.text, password);
  };

  if (
    isAuthorized &&
    localStorage.getItem("id_token") !== null &&
    localStorage.getItem("id_token") !== ""
  ) {
    return <Navigate to="/dashboard" />;
  }

  let actWidth = "50%";
  //if (windowSize.innerWidth < windowSize.innerHeight) {
  //  actWidth = "95%";
  //}
  //sx={{ m: 1, ml: 5, mr: 5, mt: 5 }}, autoFocus

  return (
    <Container
      component="main"
      sx={{ m: 0 }}
      style={{ width: "100%" }}
      maxWidth={false}
      disableGutters={true}
    >
      <Grid container rowSpacing={0} style={{ width: "100%" }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              height: "100%",
              minHeight: 75,
              bgcolor: blue[200],
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ mt: 0 }} variant="h3" color="white">
                Secure IoT systems & Own data
              </Typography>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid container sx={{mt:3}} rowSpacing={2} xs={12} sm={12} md={9} lg={9} xl={7}>
          <Grid item sx={{ mb: 3, mt: 3 }} xs={12} sm={12} md={9} lg={9} xl={9}>
            <MainCard
              sx={{
                margin: 1,
                mt: 0,
                mb: 3,
                minHeight: "30%",
              }}
              content={false}
              border={false}
              boxShadow
            >
              <Grid item sx={{m: 2}}>
                <MainCard
                  style={{ backgroundColor: blue[200], color: "white" }}
                >
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{ ml: 3, mt: 1 }}
                      variant="h4"
                      color="white"
                    >
                      Protect data privacy & bring cloud exit to IoT systems
                    </Typography>
                  </Grid>
                </MainCard>
              </Grid>
              <Grid item sx={{m: 2}}>
              <Grid container spacing={1}>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <MainCard
                    style={{ backgroundColor: green[200], color: "white", minHeight: "100%"}}
                  >
                    <Typography
                      sx={{ ml: 0, mt: 1 }}
                      variant="h5"
                      color="white"
                    >
                      No private data exposure
                    </Typography>
                  </MainCard>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <MainCard
                    style={{ backgroundColor: orange[200], color: "white", minHeight: "100%" }}
                  >
                    <Typography
                      sx={{ ml: 0, mt: 1 }}
                      variant="h5"
                      color="white"
                    >
                      Cloud exit strategy
                    </Typography>
                  </MainCard>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <MainCard
                    style={{ backgroundColor: pink[200], color: "white", minHeight: "100%" }}
                  >
                    <Typography
                      sx={{ ml: 0, mt: 1 }}
                      variant="h5"
                      color="white"
                    >
                      Low latency real-time
                    </Typography>
                  </MainCard>
                </Grid>
              </Grid>
              <Grid container spacing={1} sx={{mt:1}}>  
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <MainCard
                    style={{ backgroundColor: deepPurple[200], color: "white", minHeight: "100%" }}
                  >
                    <Typography
                      sx={{ ml: 0, mt: 1 }}
                      variant="h5"
                      color="white"
                    >
                      Decentralized backend database
                    </Typography>
                    <Typography
                      sx={{ ml: 0, mt: 1 }}
                      variant="h5"
                      color="white"
                    >
                      Kubernetes
                    </Typography>
                  </MainCard>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <MainCard
                    style={{ backgroundColor: teal[200], color: "white", minHeight: "100%" }}
                  >
                    <Typography
                      sx={{ ml: 0, mt: 1 }}
                      variant="h5"
                      color="white"
                    >
                      Edge computing with AI/ML computer vision
                    </Typography>
                  </MainCard>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <MainCard
                    style={{ backgroundColor: green[200], color: "white", minHeight: "100%" }}
                  >
                    <Typography
                      sx={{ ml: 0, mt: 1 }}
                      variant="h5"
                      color="white"
                    >
                      Network
                    </Typography>
                    <Typography
                      sx={{ ml: 0, mt: 1 }}
                      variant="h5"
                      color="white"
                    >
                      VPN management
                    </Typography>
                  </MainCard>
                </Grid>
              </Grid>
              </Grid>
            </MainCard>
            <MainCard
              sx={{
                margin: 1,
                mt: 3,
                minHeight: "50%",
                "& > *": {
                  flexGrow: 1,
                  flexBasis: "50%",
                },
              }}
              content={false}
              border={false}
              boxShadow
            >
              <Stack sx={{ height: "100%" }} spacing={0.75}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <Typography
                      sx={{ ml: 0, mt: 3, mb: 1, color: blue[200]}}
                      variant="h4"
                    >
                      System overview
                    </Typography>
                    <Box
                      component="img"
                      src={Cover}
                      sx={{ height: "100%", width: "100%" }}
                    />
                  </Grid>
                </Box>
              </Stack>
            </MainCard>
          </Grid>
          <Grid item sx={{ mb: 3, mt: 3 }} xs={12} sm={12} md={3} lg={3} xl={3}>
            <MainCard
              sx={{
                maxWidth: { xs: 500, lg: 575 },
                maxHeight: 500,
                margin: 1,
                mt: 0,
                "& > *": {
                  flexGrow: 1,
                  flexBasis: "50%",
                },
              }}
              content={false}
              border={false}
              boxShadow
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  justifyContent: "left",
                  p: 3,
                }}
              >
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginLeft: 1,
                  }}
                >
                  <Avatar
                    src={Logo}
                    variant="rounded"
                    sx={{
                      mb: 1,
                      mr: 1,
                      bgcolor: "secondary.main",
                    }}
                    style={{ height: "35px", width: "100px" }}
                  />
                </Grid>
                <Typography
                  component="h1"
                  variant="h5"
                  sx={{ ml: 1, color: blue[300] }}
                >
                  Login
                </Typography>
                <TextField
                  sx={{ mt: 5 }}
                  label="User Name"
                  type="text"
                  required
                  name="user"
                  onBlur={(e) => {
                    setUser({ text: e.target.value, error: "" });
                  }}
                />
                <TextField
                  sx={{ mt: 1, mb: 1 }}
                  label="Password"
                  type="password"
                  required
                  name="password"
                  onBlur={(e) => {
                    setPassword({ text: e.target.value, error: "" });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setPassword({ text: e.target.value, error: "" });
                      handleDoLoginWithPassword(e.target.value);
                    }
                  }}
                />
                <Typography
                  component="h1"
                  variant="h6"
                  sx={{ color: "secondary.main" }}
                >
                  {message}
                </Typography>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "right",
                    marginRight: 3,
                  }}
                >
                  <Button sx={{ mt: 3, mb: 2 }}>Sign up</Button>
                  <Button
                    onClick={handleDoLogin}
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={isLoading}
                    color="info"
                  >
                    Login
                  </Button>
                </Grid>
              </Box>
            </MainCard>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
