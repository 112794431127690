import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import GetToken from '../components/Token';

export const doConnect = createAsyncThunk(
   "connect/doConnect",
   async (thunkAPI) => {
      const config = {
         method: 'POST',
         headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authentication: `${GetToken()}`,
         },
      };
      console.log("=== connect/doConnect");
      try {
         const res = await fetch('/auth', config).then((res) => res.json());
         return res;
      } catch (error) {
         return thunkAPI.rejectWithValue();
      }
   }
);

const initialState = {
   connectMessage: '',
};

export const connectSlice = createSlice({
   name: 'connect',
   initialState,
   extraReducers: {
      [doConnect.fulfilled]: (state, action) => {
         console.log(action.payload);
         if (action.payload === 'unauthorized') {
            state.connectMessage = 'unauthorized';
         }
      },
      [doConnect.rejected](state, action) {
         console.log("Connect API rejected");
         state.connectMessage = 'Failed to contact server';
      },
      [doConnect.pending](state, action) {
         console.log('Loading');
         state.connectMessage = 'Connecting ...';
      },
   },
});

const { reducer } = connectSlice;
export default reducer;