var WebRtcDataChannel = (function () {
    //uniquePtr is shared by WebRtcDataChannel and dataChannelOnMessage for async api request
    //assume there is only one WebRtcDataChannel created
    
    var uniquePtr = null;
    var WebRtcDataChannel = function WebRtcDataChannel(user) {
      this.pc = null;
      this.pcOptions = { optional: [{ DtlsSrtpKeyAgreement: true }] };

      this.mediaConstraints = {
        offerToReceiveAudio: false,
        offerToReceiveVideo: false,
      };

      this.iceServers = null;
      this.dataChannel = null;
      this.dataChannelConnected = false;
      this.api = null;
      this.earlyCandidates = [];
      this.id_token = localStorage.getItem("id_token");
      this.user = user;
      this.isConnected = false;
      this.sfu = false;
      this.connectedCallback = null;
      this.apiMap = new Map();
      //if(!this.sfu) {
        this.connect();
      //}

      uniquePtr = this;
    };
    
    WebRtcDataChannel.prototype.setConnectedCallback = function (callback) {
        this.connectedCallback = callback;
    };

    WebRtcDataChannel.prototype.connect = function () {
      if (this.isConnected) {
        this.disconnect();
      }

      if (!this.iceServers) {
        //console.log("WebRtcDataChannel Get IceServers");
        const config = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user: this.user,
            token: this.id_token,
          }),
          credentials: "include",
        };
        //console.log("+++++++++++++++++++++++ config:" + JSON.stringify(config));
        //"/api/geticeservers?token=" + this.id_token + "&user=" + this.user,
        var bind = this;
        fetch("/api/geticeservers", config)
          .then((res) => res.json())
          .then((json) => {
            //console.log("geticeservers:" + JSON.stringify(json));
            bind.onReceiveGetIceServers.call(bind, json);
          })
          .catch((error) => {
            bind.onError("WebRtcDataChannel getIceServers " + error);
          });
      } else {
        this.onReceiveGetIceServers(this.iceServers);
      }
    };

    WebRtcDataChannel.prototype.dataChannelOnMessage = function (evt) {
      if(!uniquePtr) {
        console.log("dataChannelOnMessage internal error");
        return;
      }
      if(uniquePtr.connectedCallback) {
        uniquePtr.connectedCallback();
      }
      let apiResponse;
      try {
        apiResponse = JSON.parse(evt.data);
      } catch (e) {
        console.log("dataChannelOnMessage invalid json:" + evt.data);
        return;
      }
      if(!apiResponse.hasOwnProperty("api") || !apiResponse.hasOwnProperty("response")) {
        console.log("dataChannelOnMessage invalid response:" + evt.data);
        return;
      }

      if(!uniquePtr.apiMap.has(apiResponse.api)) {
        console.log("dataChannelOnMessage internal error:" + evt.data);
        return;
      }

      let callback = uniquePtr.apiMap.get(apiResponse.api);
      //apiResponse.response is string, not json object.
      callback(apiResponse.response);
      uniquePtr.apiMap.delete(apiResponse.api);
    };

    WebRtcDataChannel.prototype.apiRequest = function (req) {
      if(!this.dataChannelConnected) {
        var bind = this;
        return new Promise((resolve, reject) => {
          const config = {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user: bind.user,
              token: bind.id_token,
              peerid: bind.pc.peerid,
              request: req,
            }),
            credentials: "include",
          };
          //console.log("+++++++++++++++++++++++ call config:" + JSON.stringify(config));
          fetch(
            "/api/datachannel",
            config
          ).then((res) => res.json()).then((json) => {
            //console.log("============ DataChannel apiRequest res:" + JSON.stringify(json));
            resolve(json.response);
          }).catch((fetch_error) => {
            const error = {
              error: "DataChannel apiRequest fetch error: " + fetch_error + ", api: " + JSON.stringify(req),
            };
            reject(error);
          });
        });
      }

      if (!this.pc || !this.dataChannel || !this.dataChannelConnected) {
        console.log(
          "WebRtcDataChannel apiRequest error: connectioned is not established yet"
        );
        return new Promise((resolve, reject) => {
          const error = {
            error: "connectioned is not established yet",
          };
          reject(error);
        });
      }

      let channel = this.dataChannel;
      //console.log("***************** WebRtcDataChannel apiRequest:" + JSON.stringify(req));
      if(!req.hasOwnProperty("api")) {
        return new Promise((resolve, reject) => {
            const error = {
              error: "apiRequest input error, no api specified",
            };
            reject(error);
        });
      }

      if(!req.hasOwnProperty("api")) {
        return new Promise((resolve, reject) => {
            const error = {
              error: "apiRequest input error, no api specified",
            };
            reject(error);
        });
      }

      if(uniquePtr.apiMap.has(req.api)) {
        console.log("apiRequest error, api is in progress, please request later:" + req.api);
        return new Promise((resolve, reject) => {
          const error = {
            error: "apiRequest error, api is in progress, please request later",
          };
          reject(error);
        });
      }

      var bind = this;
      return new Promise((resolve, reject) => {
        channel.send(JSON.stringify(req));
        uniquePtr.apiMap.set(req.api, resolve); 

        setTimeout(() => {
          if (uniquePtr.apiMap.has(req.api)) {
            console.log("local datachannel recv error");
            const error = {
              error: "API request timeout",
            };
            reject(error);
          }
        }, 5000);
      });
    };

    /**
     * Disconnect a WebRTC Stream and clear videoElement source
     */
    WebRtcDataChannel.prototype.disconnect = function () {
      this.dataChannelConnected = false;
      if (this.videoElement) {
        this.videoElement.src = "";
      }
      if (this.pc) {
        const config = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user: this.user,
            token: this.id_token,
            peerid: this.pc.peerid,
          }),
          credentials: "include",
        };
        //console.log("+++++++++++++++++++++++ hangup config:" + JSON.stringify(config));
        fetch("/api/hangup", config)
          .then((res) => {
            //console.log("============ hangup res:" + JSON.stringify(res));
          })
          .catch((error) => {
            console.log("/api/hangup " + error);
          });

        try {
          this.pc.close();
        } catch (e) {
          console.log("Failure close peer connection:" + e);
        }
        this.pc = null;
      }
    };

    /*
     * GetIceServers callback
     */
    WebRtcDataChannel.prototype.onReceiveGetIceServers = function (iceServers) {
      this.iceServers = iceServers;
      this.pcConfig = iceServers || { iceServers: [] };
      try {
        this.createPeerConnection();

        // clear early candidates
        this.earlyCandidates.length = 0;

        // create Offer
        var bind = this;
        this.pc.createOffer(this.mediaConstraints).then(
          function (sessionDescription) {
            //console.log("Create offer:" + JSON.stringify(sessionDescription));

            bind.pc.setLocalDescription(
              sessionDescription,
              function () {
                const config = {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    user: bind.user,
                    token: bind.id_token,
                    peerid: bind.pc.peerid,
                    url: encodeURIComponent("dataChannel"),
                    offer: sessionDescription,
                  }),
                  credentials: "include",
                };
                //console.log("+++++++++++++++++++++++ call config:" + JSON.stringify(config));
                fetch("/api/call", config)
                  .then((res) => res.json())
                  .then((json) => {
                    //console.log("============ res:" + JSON.stringify(json));
                    bind.onReceiveCall.call(bind, json);
                  })
                  .catch((error) => {
                    bind.onError("/api/call " + error);
                  });
              },
              function (error) {
                console.log(
                  "setLocalDescription error:" + JSON.stringify(error)
                );
              }
            );
          },
          function (error) {
            alert("Create offer error:" + JSON.stringify(error));
          }
        );
      } catch (e) {
        this.disconnect();
        alert("connect error: " + e);
      }
    };

    WebRtcDataChannel.prototype.getIceCandidate = function () {
      var bind = this;
      const config = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user: bind.user,
          token: bind.id_token,
          peerid: bind.pc.peerid,
        }),
        credentials: "include",
      };
      //console.log("+++++++++++++++++++++++ getIceCandidate config:" + JSON.stringify(config));
      fetch("/api/getIceCandidate", config)
        .then((res) => res.json())
        .then((json) => {
          //console.log("============ getIceCandidate res:" + JSON.stringify(json));
          bind.onReceiveCandidate.call(bind, json);
        })
        .catch((error) => {
          bind.onError("/api/getIceCandidate " + error);
        });
    };

    /*
     * create RTCPeerConnection
     */
    WebRtcDataChannel.prototype.createPeerConnection = function () {
      //console.log("createPeerConnection  config: " + JSON.stringify(this.pcConfig) + " option:"+  JSON.stringify(this.pcOptions));
      this.pc = new RTCPeerConnection(this.pcConfig, this.pcOptions);
      var pc = this.pc;
      pc.peerid = Math.random();

      var bind = this;
      pc.onicecandidate = function (evt) {
        bind.onIceCandidate.call(bind, evt);
      };
      //pc.onaddstream    = function(evt) { bind.onAddStream.call(bind,evt); };
      pc.oniceconnectionstatechange = function (evt) {
        //console.log("oniceconnectionstatechange  state: " + pc.iceConnectionState);

        if (pc.iceConnectionState === "connected") {
          //bind.videoElement.style.opacity = "1.0";
        } else if (pc.iceConnectionState === "disconnected") {
          //bind.videoElement.style.opacity = "0.25";
        } else if (
          pc.iceConnectionState === "failed" ||
          pc.iceConnectionState === "closed"
        ) {
          //bind.videoElement.style.opacity = "0.5";
        } else if (pc.iceConnectionState === "new") {
          bind.getIceCandidate.call(bind);
        }
      };
      pc.ondatachannel = function (evt) {
        
        console.log(
          "remote datachannel created:" +
            JSON.stringify(evt)
        );
        bind.dataChannelConnected = true;
        evt.channel.onopen = function () {
          //console.log("remote datachannel open");connectedCallback
          this.send("remote channel openned");
        };
        evt.channel.onmessage = function (event) {
          //console.log("remote datachannel recv:"+JSON.stringify(event.data));
        };
      };

      try {
        this.dataChannel = pc.createDataChannel("ClientDataChannel");
        this.dataChannel.onopen = function () {
          this.send("local channel openned");
        };

        this.dataChannel.onmessage = this.dataChannelOnMessage;
        /*
        this.dataChannel.onmessage = function (evt) {
          if(bind.connectedCallback) {
            bind.connectedCallback();
          }
        };
        */
      } catch (e) {
        console.log("Cannot create datachannel error: " + e);
      }

      //console.log("Created RTCPeerConnnection with config: " + JSON.stringify(this.pcConfig) + "option:"+  JSON.stringify(this.pcOptions) );
      return pc;
    };

    /*
     * RTCPeerConnection IceCandidate callback
     */
    WebRtcDataChannel.prototype.onIceCandidate = function (event) {
      if (event.candidate) {
        //console.log("-----------------------------" + JSON.stringify(event.candidate));
        if (this.pc.currentRemoteDescription) {
          //console.log("------ 1");
          if (!(JSON.stringify(event.candidate).indexOf("UDP") === -1) || !(JSON.stringify(event.candidate).indexOf("udp") === -1)) {
            //console.log("=================================" + JSON.stringify(event.candidate));
            this.addIceCandidate(this.pc.peerid, event.candidate);
          }
        } else {
          //console.log("------ 2");
          if (!(JSON.stringify(event.candidate).indexOf("UDP") === -1) || !(JSON.stringify(event.candidate).indexOf("udp") === -1)) {
            this.earlyCandidates.push(event.candidate);
          }
        }
      } else {
        console.log("End of candidates.");
      }
    };

    WebRtcDataChannel.prototype.addIceCandidate = function (peerid, candidate) {
      var bind = this;
      const config = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user: bind.user,
          token: bind.id_token,
          peerid: bind.pc.peerid,
          candidate: candidate,
        }),
        credentials: "include",
      };
      //console.log("+++++++++++++++++++++++ addIceCandidate config:" + JSON.stringify(config));
      fetch("/api/addIceCandidate", config)
        .then((res) => {
          //console.log(
          //  "============ addIceCandidate res:" + JSON.stringify(res)
          //);
        })
        .catch((error) => {
          bind.onError("/api/addIceCandidate " + error);
        });
    };

    WebRtcDataChannel.prototype.onReceiveCall = function (dataJson) {
      var bind = this;
      //console.log("WebRtcDataChannel offer: " + JSON.stringify(dataJson));
      var descr = new RTCSessionDescription(dataJson);
      this.pc.setRemoteDescription(
        descr,
        function () {
          //console.log ("setRemoteDescription ok");
          while (bind.earlyCandidates.length) {
            var candidate = bind.earlyCandidates.shift();
            bind.addIceCandidate.call(bind, bind.pc.peerid, candidate);
          }

          bind.getIceCandidate.call(bind);
        },
        function (error) {
          console.log("setRemoteDescription error:" + JSON.stringify(error));
        }
      );
    };

    WebRtcDataChannel.prototype.onReceiveCandidate = function (dataJson) {
      //console.log("candidate: " + JSON.stringify(dataJson));
      if (dataJson) {
        for (var i = 0; i < dataJson.length; i++) {
          var candidate = new RTCIceCandidate(dataJson[i]);

          if (!(JSON.stringify(candidate).indexOf("udp") === -1) || !(JSON.stringify(candidate).indexOf("UDP") === -1)) {
            //console.log("Adding ICE candidate :" + JSON.stringify(candidate) );
            this.pc.addIceCandidate(
              candidate,
              function () {
                console.log("addIceCandidate OK");
              },
              function (error) {
                console.log("addIceCandidate error:" + JSON.stringify(error));
              }
            );
          }
        }
        this.pc.addIceCandidate();
      }
    };

    WebRtcDataChannel.prototype.onError = function (status) {
      console.log("onError:" + status);
    };

    return WebRtcDataChannel;
})();

module.exports = WebRtcDataChannel;
