
import { useState, useEffect, useCallback , useContext, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import VideocamOutlined from "@mui/icons-material/VideocamOutlined";
import { default as GetToken, GetUser } from "../components/Token";

import {
    Avatar,
    Box,
    Button,
    ButtonBase,
    CardContent,
    ClickAwayListener,
    Grid,
    IconButton,
    Paper,
    Popper,
    Stack,
    Tab,
    Tabs,
    Typography,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    ListItemSecondaryAction,
  } from "@mui/material";
import { blue, red } from "@mui/material/colors";
import {Context} from '../App';

//var webRtcServer = null;
var maxRow = 10;
const table_columns = [
  { title: 'File Name', field: 'name', sorter: 'string' },
  //		{ title: 'Duration', field: 'duration' },
];

const Camera = ({address}) => {
  const location = useLocation();
  const [fileRecord, setFileRecord] = useState([{name: ""}]);
  const [fileMotion, setFileMotion] = useState([{name: ""}]);
  const [Recording, setRecording] = useState(false);
  const [apiCount, setApiCount] = useState(1);
  const [sfuEnabled, setSfuEnabled] = useState(false);
  const { webRtcServer, dataChannel } = useContext(Context);

  const navigate = useNavigate();

  const config = {
    method: "GET",
  };
  //console.log("++++++++++++++++++++++++ addr: " + location.state.address);
  const targetAddr = location.state.address;

  useEffect(() => {
    //console.log("Camera useEffect WebRtcStreamer called.");
    if (webRtcServer) {
      //console.log("new WebRtcStreamer.");
      if(!dataChannel.dataChannelConnected) {
        webRtcServer.setSfuEnable(true);
        setSfuEnabled(true);
      }

      webRtcServer.connect(GetUser(), "video", targetAddr);
    }

    return () => {
      //console.log("WebRtcStreamer disconnect.");
      webRtcServer.disconnect();
      //webRtcServer = null;
    };
  }, []);

  useEffect(() => {
    //console.log("Camera useEffect listfiles called.");
/*
    const apiList ={
      api: "listfiles",
      payload: {target: "rtsp://admin:123456@192.168.160.172:554/mpeg4cif"},
    };
    webRtcServer.apiRequest(apiList).then((res)=>{console.log("+++++++++++:" + res);}).catch((error) => {
			console.log("listfiles error: " + error);
		});
*/
    const config = {
      method: "GET",
    };
    if(!Recording) {
      /*
      const fetchList = async () => {
        const res = await fetch(
          '/listfiles?target=' + targetAddr,
          config
        ).then((res) => res.json());
        let a = [];
        for(let i = 0; i < res.length && i < maxRow; i++) {
          a.push({name: res[res.length - 1 - i]});
        }
        setFileRecord(a);
      };

      fetchList().catch((error) => {
        console.log("=== fetchList error: " + error);
      });
      */

      const target = {target: targetAddr};
      const apiList ={
        api: "listfiles",
        payload: JSON.stringify(target),
      };
      dataChannel.apiRequest(apiList).then((res)=>{
        //console.log("#############################################" + JSON.stringify(res));
        let a = [];
        for(let i = 0; i < res.length && i < maxRow; i++) {
          a.push({name: res[res.length - 1 - i]});
        }
        setFileRecord(a);
      }).catch((error) => {
        console.log("listfiles error: " + JSON.stringify(error));
        setTimeout(() => {
          setApiCount(apiCount + 1);
        }, 3000);
      });

    }
  }, [Recording, apiCount]);

  useEffect(() => {
    //console.log("Camera useEffect listmotionfiles called.");

/*
    const fetchListMotion = async () => {
      const res = await fetch(
        '/listmotionfiles?target=' + targetAddr,
        config
      ).then((res) => res.json());
      
      let a = [];
      for(let i = 0; i < res.length && i < maxRow; i++) {
        a.push({name: res[res.length - 1 - i]});
      }

      setFileMotion(a);
    };
    fetchListMotion().catch((error) => {
      console.log("=== fetchListMotion error: " + error);
    });
    */
 
    const target = {target: targetAddr};
    const apiListMotion ={
      api: "listmotionfiles",
      payload: JSON.stringify(target),
    };

    dataChannel.apiRequest(apiListMotion).then((res)=>{
      //console.log("############################################# listmotionfiles" + JSON.stringify(res));
      let a = [];
      for(let i = 0; i < res.length && i < maxRow; i++) {
        a.push({name: res[res.length - 1 - i]});
      }
      setFileMotion(a);
    }).catch((error) => {
      console.log("listmotionfiles error: " + JSON.stringify(error));
    });
  }, [apiCount]);

  const clickMotion = (e) => {
    //console.log("clicked:" + e.currentTarget.textContent);
    navigate("/fileplayer", {state: {target: targetAddr, address: "motion/" + e.currentTarget.textContent}});
  };

  const clickRecord = (e) => {
    //console.log("clicked:" + e.currentTarget.textContent);
    navigate("/fileplayer", {state: {target: targetAddr, address: e.currentTarget.textContent}});
  };

  const doRecord = () => {
    if (!Recording) {
      const target = {target: targetAddr};
      const recordApi ={
        api: "record",
        payload: JSON.stringify(target),
      };
  
      dataChannel.apiRequest(recordApi).then((res)=>{
        setRecording(!Recording);
      }).catch((error) => {
        console.log("record error: " + JSON.stringify(error));
      });
      /*
      fetch("record?target=" + targetAddr, config)
        .then((response) => {
          if (response.ok) {
          }
        })
        .catch((err) => console.error("Error: ", err));
      setRecording(!Recording);
      */
    }
  };

  const stopRecord = () => {
    
    if (Recording) {
      const target = {target: targetAddr};
      const stopRecordApi ={
        api: "stoprecord",
        payload: JSON.stringify(target),
      };
  
      dataChannel.apiRequest(stopRecordApi).then((res)=>{
        setRecording(!Recording);
      }).catch((error) => {
        console.log("record error: " + JSON.stringify(error));
      });
      /*
      fetch("stoprecord?target=" + targetAddr, config)
        .then((response) => {
          if (response.ok) {
          }
        })
        .catch((err) => console.error("Error: ", err));
      setRecording(!Recording);
      */
    }
  };

  return (
    <Grid container rowSpacing={2}>
      {/* row 1 */}
      <Grid item xs={12}>
        <Typography variant="h5">Camera - SFU:{sfuEnabled ? "On" : "Off"}</Typography>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item sx={{ mb: 1 }} xs={12} sm={12} md={9} lg={9} xl={7}>
          <video id="video" width="100%" height="100%" controls="controls">
            Video is not connected yet.
          </video>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={4} sm={4} lg={2} md={2} xl={2}>
          <Button sx={{ mt: 3, mb: 2, ml: 8}} variant="contained" color={Recording ? "warning" : "primary"} onClick={doRecord}>
            Record
          </Button>
        </Grid>
        <Grid item xs={4} sm={4} lg={2} md={2} xl={2}>
          <Button sx={{ mt: 3, mb: 2, ml: 8}} variant="contained" color="primary" onClick={stopRecord}>
            Stop
          </Button>
        </Grid>
      </Grid>
      {/* row 2 */}
      <Grid item xs={12}>
        <Typography variant="h5">Motion Files</Typography>
      </Grid>
      <Grid item sx={{ mb: 1, ml: 3, mr: 3 }} xs={12}>
        {fileMotion.map((item, index) => (
          <ListItemButton divider onClick={clickMotion} key={index}>
            <ListItemAvatar key={index}>
              <Avatar key={index}
                sx={{
                  color: "success.main",
                  bgcolor: "success.lighter",
                }}
              >
                <VideocamOutlined />
              </Avatar>
            </ListItemAvatar>
            {item.name}
          </ListItemButton>
        ))}
      </Grid>

      {/* row 3 */}
      <Grid item xs={12}>
        <Typography variant="h5">Recorded Files</Typography>
      </Grid>
      <Grid item sx={{ mb: 1, ml: 3, mr: 3 }} xs={12}>
        {fileRecord.map((item, index) => (
          <ListItemButton divider onClick={clickRecord} key={index + 11}>
            <ListItemAvatar key={index + 11}>
              <Avatar key={index + 11}
                sx={{
                  color: "success.main",
                  bgcolor: "success.lighter",
                }}
              >
                <VideocamOutlined />
              </Avatar>
            </ListItemAvatar>
            {item.name}
          </ListItemButton>
        ))}
      </Grid>
    </Grid>
  );
};
/*
            <ListItemText
              primary={<Typography variant="subtitle1">{item.name}</Typography>}
              secondary="Today, 2:00 AM"
            />
            <ListItemSecondaryAction>
              <Stack alignItems="flex-end">
                <Typography variant="subtitle1" noWrap>
                  Duration
                </Typography>
                <Typography variant="h6" color="secondary" noWrap>
                  {}
                </Typography>
              </Stack>
            </ListItemSecondaryAction>
             */
export default Camera;