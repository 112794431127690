import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import bcrypt from 'bcryptjs-react';

export const login = createAsyncThunk(
   "auth/login",
   async ({ username, password }, thunkAPI) => {
      var salt = bcrypt.genSaltSync(10);
      var hashPassword = bcrypt.hashSync(password, salt);

      const config = {
         method: 'POST',
         headers: { 'Content-Type': 'text/html' },
         credentials: 'include',
         body: `user=${username}&password=${hashPassword}`,
      };

      try {
         const res = await fetch('/auth', config).then((res) => res.json());
         return res;
      } catch (error) {
         return thunkAPI.rejectWithValue();
      }
   }
);

const initialState = {
   isLoading: false,
   isAuthorized: false,
   message: '',
};

export const authSlice = createSlice({
   name: 'auth',
   initialState,
   extraReducers: {
      [login.fulfilled]: (state, action) => {
         //console.log(action.payload);
         state.isLoading = false;
         if (action.payload.token === 'unauthorized') {
            state.isAuthorized = false;
            state.message = 'Wrong user name or password';
         } else {
            state.isAuthorized = true;
            localStorage.setItem('id_token', action.payload.token);
         }
      },
      [login.rejected](state, action) {
         //console.log(action.error);
         state.isLoading = false;
         state.isAuthorized = false;
         state.message = 'Failed to contact server';
      },
      [login.pending](state, action) {
         //console.log('Loading');
         state.isLoading = true;
         state.message = 'Connecting ...';
      },
   },
});

const { reducer } = authSlice;
export default reducer;