
import authReducer from "./slices/auth";
import menuReducer from './slices/menu';
import connectReducer from './slices/connect';
import { configureStore } from '@reduxjs/toolkit'

const reducer = {
  auth: authReducer,
  menu: menuReducer,
  connect: connectReducer,
}

const store = configureStore({
  reducer: reducer,
  devTools: true,
})

export default store;