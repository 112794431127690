//import jwt from 'jsonwebtoken';

var WebRtcStreamer = (function() {

/** 
 * Interface with WebRTC-streamer API
 * @constructor
 * @param {string} videoElement - id of the video element tag
 * @param {string} srvurl -  url of webrtc-streamer (default is current location)
*/
var WebRtcStreamer = function WebRtcStreamer (user, videoElement, srvurl) {
	if (typeof videoElement === "string") {
		this.videoElement = document.getElementById(videoElement);
	} else {
		this.videoElement = videoElement;
	}
	this.srvurl           = srvurl;
	this.pc               = null;    

	this.pcOptions        = { "optional": [{"DtlsSrtpKeyAgreement": true} ] };

	this.mediaConstraints = { offerToReceiveAudio: true, offerToReceiveVideo: true };

	this.iceServers = null;
	this.dataChannel = null;
	this.api = null;
	this.earlyCandidates = [];
	//var token = localStorage.getItem('id_token');
	
	this.id_token = localStorage.getItem('id_token');
	this.user = user;
	this.isConnected = false;
	this.sfu = false;
/*
    if(token) {
		const date = new Date().getTime() / 1000;
		const data = jwt.decode(token);
		if (date >= data.exp) {
			this.id_token = token;
		}
    }
*/
}

WebRtcStreamer.prototype.setSfuEnable = function(enabled) {
	this.sfu = enabled;
}

/** 
 * Connect a WebRTC Stream to videoElement 
 * @param {string} videourl - id of WebRTC video stream
 * @param {string} audiourl - id of WebRTC audio stream
 * @param {string} options -  options of WebRTC call
 * @param {string} stream  -  local stream to send
*/
WebRtcStreamer.prototype.connect = function(user, videoElement, videourl, audiourl, options, localstream) {
	if (typeof videoElement === "string") {
		this.videoElement = document.getElementById(videoElement);
	} else {
		this.videoElement = videoElement;
	}
	if(this.isConnected) {
		this.disconnect();
	}
	this.isConnected = true;
	//if(this.id_token === '') {
	this.id_token = localStorage.getItem('id_token');
	this.user = user;
	//}
	// getIceServers is not already received
	if (!this.iceServers) {
		//console.log("Get IceServers");
		const config = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			user: this.user,
			token: this.id_token,
		}),
		credentials: "include",
		};
		//console.log("+++++++++++++++++++++++ config:" + JSON.stringify(config));
		//"/api/geticeservers?token=" + this.id_token + "&user=" + this.user,
		var bind = this;
		fetch(
		"/api/geticeservers",
		config
		).then((res) => res.json())
		.then((json) => {
			//console.log("============ res:" + JSON.stringify(json));
			bind.onReceiveGetIceServers.call(
				bind,
				json,
				videourl,
				audiourl,
				options,
				localstream
			);
		})
		.catch((error) => {
			//console.log("fetch error: " + error);
			bind.onError("getIceServers " + error);
		});
    /*
    request(
      "GET",
      "/api/geticeservers?token=" + this.id_token + "&user=" + this.user
    ).done(function (response) {
      if (response.statusCode === 200) {
        bind.onReceiveGetIceServers.call(
          bind,
          JSON.parse(response.body),
          videourl,
          audiourl,
          options,
          localstream
        );
      } else {
        bind.onError("getIceServers " + response.statusCode);
      }
    });
	*/
	} else {
		this.onReceiveGetIceServers(
		this.iceServers,
		videourl,
		audiourl,
		options,
		localstream
		);
	}
}

/** 
 * Disconnect a WebRTC Stream and clear videoElement source
*/
WebRtcStreamer.prototype.disconnect = function() {		
	if (this.videoElement) {
		this.videoElement.src = "";
	}
	this.isConnected = false;
	if (this.pc) {
		const config = {
			method: "POST",
			headers: {
			  Accept: "application/json",
			  "Content-Type": "application/json",
			},
			body: JSON.stringify({
			  user: this.user,
			  token: this.id_token,
			  peerid: this.pc.peerid,
			}),
			credentials: "include",
		};
		//console.log("+++++++++++++++++++++++ hangup config:" + JSON.stringify(config));
		fetch(
		  "/api/hangup",
		  config
		).then((res) => {
			//console.log("============ hangup res:" + JSON.stringify(res));
		})
		.catch((error) => {
			console.log("/api/hangup " + error);
		});
		//request("GET" , this.srvurl + "/api/hangup?peerid="+this.pc.peerid);
		//request("GET" , "/tomqtt/api/hangup?token=" + this.id_token + "&peerid=" + this.pc.peerid);

		try {
			this.pc.close();
		}
		catch (e) {
			console.log ("Failure close peer connection:" + e);
		}
		this.pc = null;
	}
}    

/*
* GetIceServers callback
*/
WebRtcStreamer.prototype.onReceiveGetIceServers = function(iceServers, videourl, audiourl, options, stream) {
	this.iceServers       = iceServers;
	this.pcConfig         = iceServers || {"iceServers": [] };
	try {            
		this.createPeerConnection();
		/*
		//var callurl = this.srvurl + "/api/call?peerid="+ this.pc.peerid+"&url="+encodeURIComponent(videourl);
		var callurl = "/tomqtt/api/call?token=" + this.id_token + "&peerid="+ this.pc.peerid+"&url="+encodeURIComponent(videourl);
		if (audiourl) {
			callurl += "&audiourl="+encodeURIComponent(audiourl);
		}
		if (options) {
			callurl += "&options="+encodeURIComponent(options);
		}
		*/
		if (stream) {
			this.pc.addStream(stream);
		}

                // clear early candidates
		this.earlyCandidates.length = 0;

		// create Offer
		var bind = this;
		this.pc.createOffer(this.mediaConstraints).then(function(sessionDescription) {
			//console.log("Create offer:" + JSON.stringify(sessionDescription));
			
			bind.pc.setLocalDescription(sessionDescription
				, function() {
					const config = {
						method: "POST",
						headers: {
						  Accept: "application/json",
						  "Content-Type": "application/json",
						},
						body: JSON.stringify({
						  user: bind.user,
						  token: bind.id_token,
						  peerid: bind.pc.peerid,
						  //url: encodeURIComponent(videourl),
						  sfu: bind.sfu,
						  url: videourl,
						  offer: sessionDescription,
						}),
						credentials: "include",
					};
					//console.log("+++++++++++++++++++++++ call config:" + JSON.stringify(config));
					fetch(
					  "/api/call",
					  config
					).then((res) => res.json()).then((json) => {
						//console.log("============ res:" + JSON.stringify(json));
						bind.onReceiveCall.call(bind, json);
					})
					.catch((error) => {
						bind.onError("/api/call " + error);
					});
					/*
					request("POST" , callurl, { body: JSON.stringify(sessionDescription) })
						.done( function (response) { 
							if (response.statusCode === 200) {
								console.log("+++ response.body:" + response.body);
								bind.onReceiveCall.call(bind,JSON.parse(response.body));
							}
							else {
								bind.onError("call " + response.statusCode);
							}
						}
					);
					*/					
				}
				, function(error) {
					console.log ("setLocalDescription error:" + JSON.stringify(error)); 
				} );
			
		}, function(error) { 
			alert("Create offer error:" + JSON.stringify(error));
		});

	} catch (e) {
		this.disconnect();
		alert("connect error: " + e);
	}	    
}


WebRtcStreamer.prototype.getIceCandidate = function() {
	var bind = this;
	/*
	//request("GET" , this.srvurl + "/api/getIceCandidate?peerid=" + this.pc.peerid)
	request("GET" ,  "/tomqtt/api/getIceCandidate?token=" + this.id_token + "&peerid=" + this.pc.peerid)
		.done( function (response) { 
			if (response.statusCode === 200) {
				bind.onReceiveCandidate.call(bind,JSON.parse(response.body));
			}
			else {
				bind.onError("getIceCandidate" + response.statusCode);
			}
		}
	);
	*/
	const config = {
		method: "POST",
		headers: {
		  Accept: "application/json",
		  "Content-Type": "application/json",
		},
		body: JSON.stringify({
		  user: bind.user,
		  token: bind.id_token,
		  peerid: bind.pc.peerid,
		}),
		credentials: "include",
	};
	//console.log("+++++++++++++++++++++++ getIceCandidate config:" + JSON.stringify(config));
	if(!bind.sfu) {
		fetch(
		"/api/getIceCandidate",
		config
		).then((res) => res.json()).then((json) => {
			//console.log("============ getIceCandidate res:" + JSON.stringify(json));
			bind.onReceiveCandidate.call(bind, json);
		})
		.catch((error) => {
			bind.onError("/api/getIceCandidate " + error);
		});
	}
}
					
/*
* create RTCPeerConnection 
*/
WebRtcStreamer.prototype.createPeerConnection = function() {
	//console.log("createPeerConnection  config: " + JSON.stringify(this.pcConfig) + " option:"+  JSON.stringify(this.pcOptions));
	this.pc = new RTCPeerConnection(this.pcConfig, this.pcOptions);
	var pc = this.pc;
	pc.peerid = Math.random();		
	
	var bind = this;
	pc.onicecandidate = function(evt) { bind.onIceCandidate.call(bind, evt); };
	pc.onaddstream    = function(evt) { bind.onAddStream.call(bind,evt); };
	//pc.ontrack  = function(evt) { bind.onTrack.call(bind,evt); };
	pc.oniceconnectionstatechange = function(evt) {  
		//console.log("oniceconnectionstatechange  state: " + pc.iceConnectionState);
		if (bind.videoElement) {
			if (pc.iceConnectionState === "connected") {
				bind.videoElement.style.opacity = "1.0";
			}			
			else if (pc.iceConnectionState === "disconnected") {
				bind.videoElement.style.opacity = "0.25";
			}			
			else if ( (pc.iceConnectionState === "failed") || (pc.iceConnectionState === "closed") )  {
				bind.videoElement.style.opacity = "0.5";
			} else if (pc.iceConnectionState === "new") {
				bind.getIceCandidate.call(bind)
			}
		}
	}
	pc.ondatachannel = function(evt) {  
		evt.channel.onopen = function () {
			//console.log("remote datachannel open");
			this.send("remote channel openned");
		}
		evt.channel.onmessage = function (event) {
			//console.log("remote datachannel recv:"+JSON.stringify(event.data));
		}
	}

	try {
		this.dataChannel = pc.createDataChannel("ClientDataChannel");
		this.dataChannel.onopen = function() {
			//console.log("=========================================== local datachannel open");
			this.send("local channel openned");
		}
		this.dataChannel.onmessage = function(evt) {
			//console.log("========================================== local datachannel recv:"+JSON.stringify(evt.data));
		}
	} catch (e) {
		console.log("Cannot create datachannel error: " + e);
	}	
	
	//console.log("Created RTCPeerConnnection with config: " + JSON.stringify(this.pcConfig) + "option:"+  JSON.stringify(this.pcOptions) );
	return pc;
}


/*
* RTCPeerConnection IceCandidate callback
*/
WebRtcStreamer.prototype.onIceCandidate = function (event) {
	if (event.candidate) {
		//console.log("-----------------------------" + JSON.stringify(event.candidate));
        if (this.pc.currentRemoteDescription)  {
			if (!(JSON.stringify(event.candidate).indexOf("UDP") === -1) || !(JSON.stringify(event.candidate).indexOf("udp") === -1)) {
				//console.log("=================================" + JSON.stringify(event.candidate));
				this.addIceCandidate(this.pc.peerid, event.candidate);
			}
			
		} else {
			if (!(JSON.stringify(event.candidate).indexOf("UDP") === -1) || !(JSON.stringify(event.candidate).indexOf("udp") === -1)) {
				this.earlyCandidates.push(event.candidate);
			}
		}
	} 
	else {
		console.log("End of candidates.");
	}
}


WebRtcStreamer.prototype.addIceCandidate = function(peerid, candidate) {
	var bind = this;
	/*
	//request("POST" , this.srvurl + "/api/addIceCandidate?peerid="+peerid, { body: JSON.stringify(candidate) })
	request("POST" , "/tomqtt/api/addIceCandidate?token=" + this.id_token + "&peerid="+peerid, { body: JSON.stringify(candidate) })
		.done( function (response) { 
			if (response.statusCode === 200) {
				//console.log("addIceCandidate ok:" + response.body);
				console.log("addIceCandidate ok");
			}
			else {
				bind.onError("addIceCandidate " +response.statusCode);
			}
		}
	);
	*/
	const config = {
		method: "POST",
		headers: {
		  Accept: "application/json",
		  "Content-Type": "application/json",
		},
		body: JSON.stringify({
		  user: bind.user,
		  token: bind.id_token,
		  peerid: bind.pc.peerid,
		  candidate: candidate,
		}),
		credentials: "include",
	};
	//console.log("+++++++++++++++++++++++ addIceCandidate config:" + JSON.stringify(config));
	if(!bind.sfu) {
		fetch(
		"/api/addIceCandidate",
		config
		).then((res) => {
			//console.log("============ addIceCandidate res:" + JSON.stringify(res));
		})
		.catch((error) => {
			bind.onError("/api/addIceCandidate " + error);
		});
	}
}
				
/*
* RTCPeerConnection AddTrack callback
*/
WebRtcStreamer.prototype.onAddStream = function(event) {
	//console.log("================= Remote track added:" +  JSON.stringify(event.stream));
	//console.log(event.stream);	
	this.videoElement.srcObject = event.stream;
	var promise = this.videoElement.play();
	if (promise !== undefined) {
	  var bind = this;
	  promise.catch(function(error) {
		console.warn("error:"+error);
		bind.videoElement.setAttribute("controls", true);
	  });
	}
}

/*
* RTCPeerConnection AddTrack callback
*/
WebRtcStreamer.prototype.onTrack = function(event) {
	console.log(event.streams);
	this.videoElement.srcObject = event.streams[0];
	var promise = this.videoElement.play();
	if (promise !== undefined) {
		var bind = this;
		promise.catch(function(error) {
		  console.warn("error:"+error);
		  bind.videoElement.setAttribute("controls", true);
		});
	  }
}

/*
* AJAX /call callback
*/
WebRtcStreamer.prototype.onReceiveCall = function(dataJson) {
	var bind = this;
	//console.log("offer: " + JSON.stringify(dataJson));
	var descr = new RTCSessionDescription(dataJson);
	this.pc.setRemoteDescription(descr
		, function()      { 
			console.log ("setRemoteDescription ok");
			if(!bind.sfu) {
				while (bind.earlyCandidates.length) {
					var candidate = bind.earlyCandidates.shift();
					bind.addIceCandidate.call(bind, bind.pc.peerid, candidate);				
				}
				
				bind.getIceCandidate.call(bind)
			}
		}
		, function(error) { 
			console.log ("setRemoteDescription error:" + error); 
			//console.log ("setRemoteDescription error:" + JSON.stringify(error)); 
	});

	if(bind.sfu) {
		let sdp = dataJson.sdp;
		if(sdp.includes("candidate:")) {
			var pos = sdp.indexOf("candidate:");
			var str = sdp.substring(pos);
			//console.log("str:" + str);
			var res = str.substring(0, str.indexOf("\r\n"));
			//console.log("res:" + res);
			var candi = {candidate: "", sdpMid: 0, sdpMLineIndex: 0, usernameFragment: null};
			candi.candidate = res;
			//console.log("============ onReceiveCandidate:" + JSON.stringify(candi));
			bind.onReceiveCandidate.call(bind, candi);
		}
	}
}	

/*
* AJAX /getIceCandidate callback
*/
WebRtcStreamer.prototype.onReceiveCandidate = function(dataJson) {
	//console.log("candidate: " + JSON.stringify(dataJson));
	if (dataJson) {
		for (var i=0; i<dataJson.length; i++) {
			var candidate = new RTCIceCandidate(dataJson[i]);
			
			if (!(JSON.stringify(candidate).indexOf("udp") === -1) || !(JSON.stringify(candidate).indexOf("UDP") === -1)) {
				//console.log("Adding ICE candidate :" + JSON.stringify(candidate) );
				this.pc.addIceCandidate(candidate
					, function()      { console.log ("addIceCandidate OK"); }
					, function(error) { console.log ("addIceCandidate error:" + JSON.stringify(error)); } );
			}
		}
		this.pc.addIceCandidate();
	}
}


/*
* AJAX callback for Error
*/
WebRtcStreamer.prototype.onError = function(status) {
	console.log("onError:" + status);
}

return WebRtcStreamer;
})();
module.exports = WebRtcStreamer;
//if (typeof module !== 'undefined' && typeof module.exports !== 'undefined')
//	module.exports = WebRtcStreamer;
//else
//	window.WebRtcStreamer = WebRtcStreamer;
