import { useState, useCallback, useContext} from "react";
// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import {Context} from '../../../App';

import { DashboardOutlined, LoginOutlined, ProfileOutlined } from '@ant-design/icons';
import RouterOutlined from "@mui/icons-material/RouterOutlined";
import VideocamOutlined from "@mui/icons-material/VideocamOutlined";
import SensorsOutlined from "@mui/icons-material/SensorsOutlined";


// icons
const DashboardIcons = {
    DashboardOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
    id: 'group-dashboard',
    title: 'Places',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: DashboardIcons.DashboardOutlined,
            breadcrumbs: false
        },
        {
            id: 'devices',
            title: 'Devices',
            type: 'item',
            url: '/devices',
            icon: DashboardIcons.DashboardOutlined,
            breadcrumbs: false
        }
    ]
};


// assets


// icons
const PageIcons = {
    RouterOutlined,
    VideocamOutlined,
    SensorsOutlined
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
    id: 'devices',
    title: 'Devices',
    type: 'group',
    children: [
        {
            id: 'gateway',
            title: 'Gateway',
            type: 'item',
            url: '/gateway',
            icon: PageIcons.RouterOutlined,
            target: true
        },
        {
            id: 'camera',
            title: 'Camera',
            type: 'item',
            url: '/camera',
            icon: PageIcons.VideocamOutlined,
            target: true
        },
        {
            id: 'sensor',
            title: 'Sensor',
            type: 'item',
            url: '/plug',
            icon: PageIcons.SensorsOutlined,
            target: true
        }
    ]
};

const gateways = {
    id: 'gateways',
    title: 'Gateways',
    type: 'group',
    children: []
};

const cameras = {
    id: 'cameras',
    title: 'Cameras',
    type: 'group',
    children: []
};

const sensors = {
    id: 'sensors',
    title: 'Sensors',
    type: 'group',
    children: []
};

export const initMenuItems = {
    items: [dashboard, gateways, cameras, sensors]
};
// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
    const {navMenu, freshNavMenu} = useContext(Context);
    //console.log("===================== Nav menu:" + JSON.stringify(navMenu));
    const navGroups = navMenu.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Nothing.
                    </Typography>
                );
        }
    });

    return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
