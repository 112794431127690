
import { useState, useEffect, useCallback , useContext, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    Avatar,
    Box,
    Button,
    Grid,
    Stack,
    Typography,
    ListItemAvatar,
  } from "@mui/material";

import PowerOutlined from "@mui/icons-material/PowerOutlined";
import PowerOffOutlined from "@mui/icons-material/PowerOffOutlined";
import MainCard from "../components/MainCard";
import { useLocation } from 'react-router-dom';
import { default as GetToken, GetUser } from "../components/Token";
import ReactApexChart from 'react-apexcharts';
import {Context} from '../App';

const Plug = ({address}) => {
  const location = useLocation();

  const [deviceInfo, setDeviceInfo] = useState({});
  const [powerState, setPowerState] = useState({power:"off"});
  const [data, setData] = useState({});
  const [rtdata, setRtData] = useState({rtdata:[]});
  const [toggle, setToggle] = useState(1);
  const [apiCount, setApiCount] = useState(1);

  const [seriesData, updateSeriesData] = useState([]);

  const { dataChannel } = useContext(Context);

  const targetAddr = location.state.address;

  const series = [
    {
      name: "power mW",
      data: seriesData,
    },
  ];


  const token = GetToken();
  const target = {target: targetAddr};

  useEffect(() => {
    console.log("Plug useEffect listfiles called.");
    const config = {
      method: "GET",
    };


  /*
    const fetchDeviceInfo = async () => {

      const res = await fetch(
        '/device_info?target=' + location.state.address,
        config
      ).then((res) => res.json());
      setDeviceInfo(res);
    };

    fetchDeviceInfo().catch((error) => {
      console.log("=== Plug fetchDeviceInfo error: " + error);
    });
*/
    let apiError = false;

    const apiList = {
      api: "device_info",
      payload: JSON.stringify(target),
    };
    
    dataChannel.apiRequest(apiList).then((res)=>{
      //console.log("device_info +++++++++++++++++ : " + JSON.stringify(res));
      setDeviceInfo(JSON.parse(res));
    }).catch((error) => {
      console.log("device_info error: " + JSON.stringify(error));
      apiError = true;

      setTimeout(() => {
        setApiCount(apiCount + 1);
      }, 3000);
    });

    const apiPowerState = {
      api: "power_state",
      payload: JSON.stringify(target),
    };
    
    dataChannel.apiRequest(apiPowerState).then((res)=>{
      //console.log("device_info +++++++++++++++++ : " + JSON.stringify(res));
      setPowerState(JSON.parse(res));
    }).catch((error) => {
      console.log("device_info error: " + JSON.stringify(error));
      apiError = true;
    });

    const apiData = {
      api: "data",
      payload: JSON.stringify(target),
    };
    
    dataChannel.apiRequest(apiData).then((res)=>{
      //console.log("device_info +++++++++++++++++ : " + JSON.stringify(res));
      setData(JSON.parse(res));
    }).catch((error) => {
      console.log("device_info error: " + JSON.stringify(error));
      apiError = true;
    });

    const apiRtData = {
      api: "rtdata",
      payload: JSON.stringify(target),
    };
    
    dataChannel.apiRequest(apiRtData).then((res)=>{
      //console.log("device_info +++++++++++++++++ : " + JSON.stringify(res));
      
      let a = res;
      a.unshift(1);
      updateSeriesData(a);
    }).catch((error) => {
      console.log("device_info error: " + JSON.stringify(error));
      apiError = true;
    });

/*
    const fetchData = async () => {
      const res = await fetch(
        '/data?target=' + location.state.address,
        config
      ).then((res) => res.json());
      setData(res);
    };

    fetchData().catch((error) => {
      console.log("=== Plug fetchData error: " + error);
    });

    const fetchRtData = async () => {
      const res = await fetch(
        '/rtdata?target=' + location.state.address,
        config
      ).then((res) => res.json());
      updateSeriesData(res.rtdata);
    };

    fetchRtData().catch((error) => {
      console.log("=== Plug fetchRtData error: " + error);
    });

    const fetchPowerState = async () => {
      const res = await fetch(
        '/power_state?target=' + location.state.address,
        config
      ).then((res) => res.json());
      setPowerState(res);
    };

    fetchPowerState().catch((error) => {
      console.log("=== Plug fetchPowerState error: " + error);
    });
*/
    if(apiError) {
      console.log("+++++++++++++++++ apiError ");
      setTimeout(() => {
        console.log("+++++++++++++++++ setApiCount ");
        setApiCount(apiCount + 1);
      }, 3000);
    }
  }, [toggle, apiCount]);

  const doControl = () => {
    const playload = {api: "control", id: targetAddr, operation: powerState.power === "on" ? "power_off" : "power_on"};
    const apiControl = {
      api: "control",
      payload: JSON.stringify(playload),
    };
    
    dataChannel.apiRequest(apiControl).then((res)=>{
      //console.log("apiControl +++++++++++++++++ : " + JSON.stringify(res));
    }).catch((error) => {
      console.log("apiControl error: " + JSON.stringify(error));
    });
    /*
    const config = {
      method: "GET",
    };

    fetch("/tomqtt/api/control?token=" + token + "&id=" + location.state.address + "&operation=power_on", config)
      .then((response) => {
        if (response.ok) {
        }
      })
      .catch((err) => console.error("Error: ", err));
    */
    setTimeout(() => {
      //console.log("+++++++++++++++++ setToggle: " + toggle);
      setToggle(toggle + 1);
    }, 4000);
  };

  const barHeight = 200;

  // chart options
  const barChartOptions = {
    chart: {
      type: "bar",
      height: barHeight,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "45%",
        borderRadius: 4,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
  };

  return (
    <Grid container rowSpacing={2} columnSpacing={2.75}>
      {/* row 1 */}
      <Grid item xs={12}>
        <Typography variant="h5">State:</Typography>
      </Grid>
      <Grid item sx={{ mb: 1 }} xs={12} sm={3} md={3} lg={2} xl={2}>
        <MainCard contentSX={{ p: 2.25 }}>
          <Box>
            <Stack spacing={0.5}>
              <Button
                sx={{ mt: 1, mb: 1, ml: 1, mr: 1 }}
                variant="contained"
                color={powerState.power === "on" ? "primary" : "secondary"}
                onClick={doControl}
              >
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      color: "success.main",
                      bgcolor: "success.lighter",
                    }}
                  >
                    {powerState.power === "on" ? <PowerOutlined /> : <PowerOffOutlined />}
                  </Avatar>
                </ListItemAvatar>
                {powerState.power === "on" ? "On" : "Off"}
              </Button>
            </Stack>
          </Box>
        </MainCard>
      </Grid>
      {/* row 2 */}
      <Grid item xs={12}>
        <Typography variant="h5">Live Chart:</Typography>
      </Grid>
      <Grid item sx={{ mb: 1 }} xs={12} sm={9} md={9} lg={6} xl={6}>
        <MainCard contentSX={{ p: 2.25 }}>
          <Box sx={{ p: 2, pb: 0 }}>
            <ReactApexChart options={barChartOptions} series={series} type="line" height={barHeight} />
          </Box>
        </MainCard>
      </Grid>
      {/* row 2 */}
      <Grid item xs={12}>
        <Typography variant="h5">Data:</Typography>
      </Grid>
      <Grid item sx={{ mb: 1 }} xs={12} sm={9} md={9} lg={6} xl={6}>
        <MainCard contentSX={{ p: 2.25 }}>
          <Box sx={{ p: 2, pb: 0 }}>
            <Stack spacing={0.5}>
              <Typography variant="h5" color="textSecondary">
                Today's running time: {(data.today_runtime / 60).toFixed(2)} hour
              </Typography>
              <Typography variant="h5" color="textSecondary">
                Month's running time: {(data.month_runtime / 60).toFixed(2)} hour
              </Typography>
              <Typography variant="h5" color="textSecondary">
                Today's energy: {(data.today_energy / 1000).toFixed(3)} kWh
              </Typography>
              <Typography variant="h5" color="textSecondary">
                Month's energy: {(data.month_energy / 1000).toFixed(3)} kWh
              </Typography>
              <Typography variant="h5" color="textSecondary">
                Current power: {(data.current_power / 1000).toFixed(3)} W
              </Typography>
              <Typography variant="h5" color="textSecondary">
                Local time: {data.local_time}
              </Typography>
            </Stack>
          </Box>
        </MainCard>
      </Grid>
      {/* row 3 */}
      <Grid item xs={12}>
        <Typography variant="h5">Information:</Typography>
      </Grid>
      <Grid item sx={{ mb: 1 }} xs={12} sm={9} md={9} lg={6} xl={6}>
        <MainCard contentSX={{ p: 2.25 }}>
          <Box sx={{ p: 2, pb: 0 }}>
            <Stack spacing={0.5}>
              <Typography variant="h5" color="textSecondary">
                Device ID: {deviceInfo.device_id}
              </Typography>
              <Typography variant="h5" color="textSecondary">
                Firmware Version: {deviceInfo.fw_ver}
              </Typography>
              <Typography variant="h5" color="textSecondary">
                Hardware Version: {deviceInfo.hw_ver}
              </Typography>
              <Typography variant="h5" color="textSecondary">
                Type: {deviceInfo.type}
              </Typography>
              <Typography variant="h5" color="textSecondary">
                Model: {deviceInfo.model}
              </Typography>
              <Typography variant="h5" color="textSecondary">
                MAC Address: {deviceInfo.mac}
              </Typography>
            </Stack>
          </Box>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default Plug;
