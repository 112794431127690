import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Header from "./Header";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { Box, Grid, Toolbar, useMediaQuery } from "@mui/material";
import { Outlet } from 'react-router-dom';
import { openDrawer } from '../slices/menu';

export default function Layout({ children }) {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();

  const { drawerOpen } = useSelector((state) => state.menu);
  //let drawerOpen = true;

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
      setOpen(!open);
      dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
      setOpen(!matchDownLG);
      dispatch(openDrawer({ drawerOpen: !matchDownLG }));

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
      if (open !== drawerOpen) setOpen(drawerOpen);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Navbar open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="main"
        sx={{ width: "100%", flexGrow: 1, pr:2, pl:2 }}
      >
        <Toolbar />
          {children}
        <Outlet />
      </Box>
    </Box>
  );
}
//Todo:      <Footer />
