import logo from "./logo.svg";
import "./App.css";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Camera from "./pages/Camera";
import FilePlayer from "./pages/FilePlayer";
import Plug from "./pages/Plug";
import Gateway from "./pages/Gateway";
import Layout from "./components/Layout";
import WebRtcDataChannel from "./components/DataChannel";
import {initMenuItems} from "./components/DrawerContent/Navigation";
import ThemeCustomization from './themes';
import {createContext, useState, useEffect} from 'react';

import { useJwt } from "react-jwt";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setNavMenuItems } from './slices/menu';
import WebRtcStreamer from "./components/WebRtcStreamer";

import { default as GetToken, GetUser } from "./components/Token";

export const Context = createContext();

var gNavMenu = initMenuItems;
var webRtcServer = new WebRtcStreamer(
  GetUser(),
  "video",
  ""
);

var isConnected = false;
var dataChannel = null;

const PrivateRoute = ({ children }) => {
  //console.log("Authentication check.");
  const dispatch = useDispatch();
  const [navMenu, setNavMenu] = useState(gNavMenu);
  const { navMenuItems } = useSelector((state) => state.menu);
  
  const freshNavMenu = (menu) => {
    //console.log("+++++++++++++++++ freshNavMenu ++++++++++++++++");
    // No need to setNavMenu here, Dashboard has changed the content of navMenu.
    //setNavMenu(menu);
    // Although setNavMenuItems is called, but it does not actually do anything
    // just update navMenuItems. dispatch will trigger PrivateRoute rendering.
    gNavMenu = menu;
    dispatch(setNavMenuItems({ navMenuItems: JSON.stringify(menu) }));
    isConnected = true;
  }; 

  /*
  useEffect(()=>{
    console.log("============== navMenuItems:" + navMenuItems);

    let menuItems = JSON.parse(navMenuItems);
    if(menuItems.items) {
      setNavMenu(menuItems);
    }
  }, []);


  useEffect(()=>{
    if(!navMenuItems)
    {
      setNavMenu(JSON.parse(navMenuItems));
    }
  }, [navMenuItems]);
*/
  //console.log("+++++++++++++++++ PrivateRoute ++++++++++++++++");
  /*
  useEffect(() => {
    return () => {
      isConnected = false;
      if(dataChannel) {
        console.log("App dataChannel.disconnect");
        dataChannel.disconnect();
        dataChannel = null;
      }
    };
  }, []);
*/
  const { isExpired } = useJwt(localStorage.getItem("id_token"));

  if (isExpired) {
    localStorage.setItem("id_token", "");
  }

  if(!dataChannel && !isExpired) {
    console.log("App new dataChannel");
    dataChannel = new WebRtcDataChannel(GetUser());
  }

  if(isConnected) {
    return !isExpired ? <Context.Provider value={{navMenu, freshNavMenu, webRtcServer, dataChannel}}><Layout>{children}</Layout></Context.Provider> : <Navigate to="/login" />;
  } else {
    return !isExpired ? <Context.Provider value={{navMenu, freshNavMenu, webRtcServer, dataChannel}}><Layout><Dashboard /></Layout></Context.Provider> : <Navigate to="/login" />;
  }
};

function App() {
  useEffect(() => {
    console.log("App starts");
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      isConnected = false;
      if(dataChannel) {
        console.log("App handleBeforeUnload dataChannel.disconnect");
        dataChannel.disconnect();
        dataChannel = null;
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      console.log("App Close");
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <ThemeCustomization>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/camera"
              element={
                <PrivateRoute>
                  <Camera />
                </PrivateRoute>
              }
            />
            <Route
              path="/fileplayer"
              element={
                <PrivateRoute>
                  <FilePlayer />
                </PrivateRoute>
              }
            />
            <Route
              path="/plug"
              element={
                <PrivateRoute>
                  <Plug />
                </PrivateRoute>
              }
            />
            <Route
              path="/gateway"
              element={
                <PrivateRoute>
                  <Gateway />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </Router>
    </ThemeCustomization>
  );
}

export default App;
