
import { useState, useEffect, useCallback , useContext, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    Avatar,
    Box,
    Button,
    Grid,
    Stack,
    Typography,
    ListItemAvatar,
  } from "@mui/material";

import PowerOutlined from "@mui/icons-material/PowerOutlined";
import PowerOffOutlined from "@mui/icons-material/PowerOffOutlined";
import MainCard from "../components/MainCard";
import { useLocation } from 'react-router-dom';
import { default as GetToken, GetUser } from "../components/Token";
import ReactApexChart from 'react-apexcharts';
import {Context} from '../App';

const Gateway = ({address}) => {
  const location = useLocation();

  const [deviceInfo, setDeviceInfo] = useState({});
  const [data, setData] = useState({});

  const { dataChannel } = useContext(Context);

  const targetAddr = location.state.address;
  const token = GetToken();
  const target = {target: targetAddr};

  console.log("Gateway location.state.address: " + location.state.address);
  useEffect(() => {
    console.log("Gateway useEffect listfiles called.");

    let apiError = false;

    const apiList = {
      api: "device_info",
      payload: JSON.stringify(target),
    };
    
    dataChannel.apiRequest(apiList).then((res)=>{
      setDeviceInfo(JSON.parse(res));
    }).catch((error) => {
      console.log("device_info error: " + JSON.stringify(error));
      apiError = true;

    });

    const apiData = {
      api: "data",
      payload: JSON.stringify(target),
    };
    
    dataChannel.apiRequest(apiData).then((res)=>{
      //console.log("device_info +++++++++++++++++ : " + JSON.stringify(res));
      setData(JSON.parse(res));
    }).catch((error) => {
      console.log("device_info error: " + JSON.stringify(error));
      apiError = true;
    });

    if(apiError) {
      console.log("Gateway apiError ");
    }
  }, []);

  return (
    <Grid container rowSpacing={2} columnSpacing={2.75}>
      {/* row 1 */}
      <Grid item xs={12}>
        <Typography variant="h5">Gateway state:</Typography>
      </Grid>
      <Grid item sx={{ mb: 1 }} xs={12} sm={9} md={9} lg={6} xl={6}>
        <MainCard contentSX={{ p: 2.25 }}>
          <Box sx={{ p: 2, pb: 0 }}>
            <Stack spacing={0.5}>
              <Typography variant="h5" color="textSecondary">
                Device ID: {deviceInfo.id}
              </Typography>
              <Typography variant="h5" color="textSecondary">
                Device Type: {deviceInfo.type}
              </Typography>
              <Typography variant="h5" color="textSecondary">
                Device System: {deviceInfo.system}
              </Typography>
              <Typography variant="h5" color="textSecondary">
                Device Network: {deviceInfo.network}
              </Typography>
            </Stack>
          </Box>
        </MainCard>
      </Grid>
      {/* row 2 */}
      <Grid item xs={12}>
        <Typography variant="h5">VPN:</Typography>
      </Grid>
      <Grid item sx={{ mb: 1 }} xs={12} sm={9} md={9} lg={6} xl={6}>
        <MainCard contentSX={{ p: 2.25 }}>
          <Box sx={{ p: 2, pb: 0 }}>
            <Stack spacing={0.5}>
              <Typography variant="h5" color="textSecondary">
                OpenVPN:
              </Typography>
              <Typography variant="h5" color="textSecondary">
                {deviceInfo.openvpn}
              </Typography>
            </Stack>
          </Box>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default Gateway;
