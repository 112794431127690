
import { useState, useEffect, useCallback , useContext, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import VideocamOutlined from "@mui/icons-material/VideocamOutlined";

import {
    Avatar,
    Box,
    Button,
    ButtonBase,
    CardContent,
    ClickAwayListener,
    Grid,
    IconButton,
    Paper,
    Popper,
    Stack,
    Tab,
    Tabs,
    Typography,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    ListItemSecondaryAction,
  } from "@mui/material";
import { useLocation } from 'react-router-dom';
import { default as GetToken, GetUser } from "../components/Token";
import {Context} from '../App';


//var webRtcServer = null;

const FilePlayer = () => {
  const { webRtcServer, dataChannel } = useContext(Context);

  const location = useLocation();
  useEffect(() => {
    console.log("FilePlayer useEffect WebRtcStreamer called.");
    if (webRtcServer) {
      console.log("new FilePlayer WebRtcStreamer.");
      //webRtcServer = new WebRtcStreamer(
      //  GetUser(),
      //  "video",
      //  "/webrtcapi"
      //);
      if(!dataChannel.dataChannelConnected) {
        webRtcServer.setSfuEnable(true);
      }
      webRtcServer.connect(GetUser(), "video", "file://" + location.state.target + "/" + location.state.address);
    }

    return () => {
      console.log("WebRtcStreamer disconnect.");
      webRtcServer.disconnect();
      //webRtcServer = null;
    };
  }, []);

  return (
    <Grid container rowSpacing={2} columnSpacing={2.75}>
      {/* row 1 */}
      <Grid item xs={12}>
        <Typography variant="h5">Playing File: {location.state.address}</Typography>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item sx={{ mb: 1 }} xs={12} sm={12} md={9} lg={9} xl={9}>
          <video id="video" width="100%" height="100%" controls="controls">
            Video is not connected yet.
          </video>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FilePlayer;